import React, { Fragment } from "react";
import FoodItem from "../sub_main/FoodItem";
import { useSelector, useDispatch } from "react-redux";
import { foodMenuActions } from "../../store/foodMenuSlice";

function OrderList() {
  // const ctx = useContext(OrderContext);
  const { selectedMeal } = useSelector((state) => state.foodMenu);
  const dispatch = useDispatch();
  // const selectedMeal = []

  return (
    <Fragment>
      {selectedMeal.map((foodItem) => (
        <FoodItem
          key={foodItem.id}
          id={foodItem.id}
          name={foodItem.name}
          description={foodItem["short_description"]}
          price={foodItem.price}
          image={foodItem.image}
          mealType = {foodItem.m_type}
          onAddToCard={(foodData) => {
            dispatch(foodMenuActions.addToCart(foodData));
          }}
        />
      ))}
    </Fragment>
  );
}
export default OrderList;
