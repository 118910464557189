import React from "react";
import styles from "./ErrorHandler.module.css";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const ErrorHandler = () => {
  const { loading, isErrorExist } = useSelector((state) => state.foodMenu);

  return (
    <div>
      {loading && (
        <div className={styles["loading-message"]}>
          {" "}
          <Spinner animation="border" size="sm" />
          Loading...
        </div>
      )}
      {isErrorExist && (
        <p className={styles["error-message"]}>Failed to fetch data!</p>
      )}
    </div>
  );
};
export default ErrorHandler;