import React from "react";
import MealsNav from "../components/sub_main/MealsNav";
import CardWrapper from "../components/UI/CardWrapper";
import MenuTitle from "../components/UI/MenuTitle";
import OrderList from "../components/main/OrderList";
import ErrorHandler from "../components/main/ErrorHandler";
import styles from "./Menu.module.css";
import { Row, Col } from "react-bootstrap";

function MenuList() {
  return (
    <Row className="justify-content-center">
      <Col className={`col-12 ${styles.menu}`}>
        <CardWrapper
          columns="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12"
          className={styles.orderList}
        >
          {<MealsNav />}
          <MenuTitle />
          <ErrorHandler />
          <OrderList />
        </CardWrapper>
      </Col>
    </Row>
  );
}

export default MenuList;
