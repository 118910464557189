import React, { useRef } from "react";
import styles from "./FoodItem.module.css";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { foodMenuActions } from "../../store/foodMenuSlice";

function FoodItem(props) {
  const badgeRef = useRef(null);
  const dispatch = useDispatch();
  // const { selectedType } = useSelector((state) => state.foodMenu);
  const submitHandler = () => {
    const foodData = {
      id: badgeRef.current.id,
      name: props.name,
      price: props.price,
      inputValue: +badgeRef.current.value,
    };
    props.onAddToCard(foodData);
  };

  return (
    <div>
      <Container>
        <Row className={styles.rMealImage}>
          <Col className="col-md-5 col-sm-8 col-8">
            <NavLink
              onClick={() => {
                dispatch(foodMenuActions.onSelectId(props.id));
              }}
              to={`/Menu/mealDetails/${props.mealType}/?id=${props.id}`}
            >
              <Image
                fluid
                className={`${styles.mealImage}`}
                src={props.image}
              />
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Col className="col-6 col-md-6 col-lg-6 col-xl-6 col-sm-6">
            <NavLink
              to={`/Menu/mealDetails/${props.mealType}/?id=${props.id}`}
              style={{ color: "black" }}
              onClick={() => {
                dispatch(foodMenuActions.onSelectId(props.id));
              }}
            >
              <Row className={styles.foodname}>{props.name}</Row>
              <Row className={styles.fooddescription}>{props.description}</Row>
              <Row className={styles.foodPrice}>
                {`€`}
                {props.price.toFixed(2)}
              </Row>
            </NavLink>
          </Col>
          <Col className="col-6 col-md-6 col-lg-6 col-xl-6 col-sm-6 ">
            <Row>
              <Col className="d-flex justify-content-end">
                <Form>
                  <Form.Group className="mb-2 mt-2" controlId={props.id}>
                    <Form.Label column size="sm">
                      Quantity
                    </Form.Label>
                    <Col className="d-flex justify-content-center">
                      <Form.Control
                        className={styles.form}
                        size="sm"
                        as="input"
                        type="number"
                        defaultValue="1"
                        step="1"
                        ref={badgeRef}
                        min="1"
                        max="11"
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  className={styles.customButton}
                  variant="danger"
                  size="sm"
                  onClick={submitHandler}
                >
                  + Add
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </Container>
    </div>
  );
}
export default FoodItem;
