import React from "react";
import { useSelector } from "react-redux";
import styles from "./MenuTitle.module.css";

function MenuTitle() {
  const selectedType = useSelector((state) => state.foodMenu.selectedType);
  return (
    <div>
      <p className={styles.ptitle}>
        Menu <br/>({selectedType} Cuisines)
      </p>
      <hr className={styles.hrtitle} />
    </div>
  );
}

export default MenuTitle;
