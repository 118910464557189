import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ParallaxProvider } from "react-scroll-parallax";

ReactDOM.render(
  <HashRouter>
    <GoogleReCaptchaProvider reCaptchaKey="6LfP8bIfAAAAAJoaAGXewUb2BDPFjKCTcRjCjbwf">
      <Provider store={store}>
        <ParallaxProvider>
          <App />
        </ParallaxProvider>
      </Provider>
    </GoogleReCaptchaProvider>
  </HashRouter>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
