import React, { useEffect } from "react";
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import styles from "./NavBar.module.css";
import { foodMenuActions } from "../../store/foodMenuSlice";
import CartButton from "../UI/CartButton";
import { fetchMeals } from "../../store/foodMenuThunks";
import { useLocation } from "react-router-dom";

function NavBar(props) {
  const totalCount = useSelector((state) => state.foodMenu.totalCount);
  const location = useLocation();
  const pk = new URLSearchParams(location.search).get("id");
  const type = new URLSearchParams(location.search).get("type");
  const foodOrderedData = useSelector(
    (state) => state.foodMenu.foodOrderedData
  );
  // const { selectedType, meals } = useSelector((state) => state.foodMenu);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(foodMenuActions.countTheCount());
    dispatch(foodMenuActions.countTotalAmount());
  }, [foodOrderedData, dispatch]);

  useEffect(() => {
    // if (type === "All" || type === null) {
    if (pk !== null) {
      dispatch(foodMenuActions.onSelectId(pk));
    }
    if (type !== null) {
      dispatch(foodMenuActions.onSelectType(type));
    }
    dispatch(fetchMeals());

    // } else if (type && type !== "All" ) {
    //   dispatch(fetchMealByType(type));
    // }
  }, [dispatch]);

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="md"
        variant="dark"
        className={styles.navbarClass}
      >
        <Container>
          <LinkContainer to="">
            <Navbar.Brand>Restaurant (Demo)</Navbar.Brand>
          </LinkContainer>
          {foodOrderedData.length && (
            <Row className="px-3">
              <Col className="justify-content-end">
                <CartButton
                  className={styles["button-addtoCart"]}
                  totalCount={totalCount}
                />
              </Col>
            </Row>
          )}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-start"
          >
            <Nav className={styles.navbarItem}>
              <LinkContainer to="/Home">
                <Nav.Link>
                  <i className="fa-solid fa-house"></i> Home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/Menu">
                <Nav.Link>
                  <i className="fa-solid fa-utensils"></i> Menu
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/ContactUs">
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
export default NavBar;
