import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

function ConsentBanner() {
  return (
    <div>
      {" "}
      <CookieConsent
        acceptOnScroll={true}
        acceptOnScrollPercentage={96}
        // visible={"byCookieValue"}
        style={{ textAlign: "left", backgroundColor: "#343434d6" }}
        buttonStyle={{ backgroundColor: "#faebd7eb", color: "#424242" }}
        expires={7}
      >
        Note: Our website uses cookies to protect the site from spam and abuse,
        also to provide necessary website functionality and analyze our traffic.
        By using our website, you agree to our{" "}
        <Link
          // onClick={() => {
          //   dispatch(profileSliceActions.disableHashLinks());
          // }}
          style={{ color: "#d49b2b" }}
          to={"/privacy-policy"}
        >
          Privacy Policy.
        </Link>
      </CookieConsent>
    </div>
  );
}

export default ConsentBanner;
