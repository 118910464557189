import React from "react";
import { Modal, Button } from "react-bootstrap";

function ContactFormNotifications(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Message Notification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!props.formRejection && (
          <p style={{ color: "darkgreen" }}>
            Thanks for your Contact.{" "}
          </p>
        )}

        {props.formRejection && (
          <p style={{ color: "red" }}>
            Oops! sending the message failed! Please send your message again.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button style={{backgroundColor: 'black'}} onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ContactFormNotifications;
