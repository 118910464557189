import React, { Fragment } from "react";
import styles from "./Home.module.css";
import OurStory from "./Home_Elements/OurStory";
import { Row, Col } from "react-bootstrap";
import Reservation from "./Home_Elements/Reservation";
import { useSelector } from "react-redux";
import icon from "../../src/assets/images/reshot-icon-chef-hat-J7DFG6M589.svg";
import homeBackground from "../assets/images/Side_Image2.jpg";
import { isMobile } from "react-device-detect";
import { ParallaxBanner } from "react-scroll-parallax";

function Home() {
  const { loading } = useSelector((state) => state.foodMenu);
  const mainTitle = (
    <Row>
      <Col>
        <p className={styles["main-tiles"]}>International Restaurant</p>
        <p className={styles["short-description"]}>Crafted with Love</p>
      </Col>
    </Row>
  );


  return (
    <Fragment>
      {loading ? (
        <div className={styles.divSpinner}>
          <img
            src={icon}
            className={styles["mylogo"]}
            width="40px"
            alt="spinner"
          />
        </div>
      ) : (
        <Fragment>
          {!isMobile && (
            <div className={styles["background-img"]}>{mainTitle}</div>
          )}
          {isMobile && (
            <ParallaxBanner
              className={styles["background-img1"]}
              layers={[
                {
                  image: homeBackground,
                  speed: -10,
                },
              ]}
            >
              {mainTitle}
            </ParallaxBanner>
          )}

          <OurStory />
          <Reservation />
        </Fragment>
      )}
    </Fragment>
  );
}

export default Home;
