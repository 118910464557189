import * as Yup from "yup";


export const initialValues = {
    name: "",
    street:"",
    city:"",
    postalCode:"",
    phone: "",
    email: "",

  };
  
  export const validationSchema = Yup.object({
    name: Yup.string()
      .max(25, "Name Must be 25 characters or less!")
      .required("Name Required!"),
    street: Yup.string()
      .max(100, "Street Must be 100 characters or less!")
      .required("Street Required!"),
    city: Yup.string()
      .max(15, "City Must be 15 characters or less!")
      .required("City Required!"),

    postalCode: Yup.string()
      .max(11, "Postal Code is too long")
      .required("postalCode Required!"),
    phone: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Phone Required!"),
  
    email: Yup.string().email("Invalid email address (@ and . are missing)").required("Email Required!"),

  });

