import React from "react";
import styles from "./PrivacyPolicy.module.css";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <div className={styles.divPolicy}>
      {" "}
      <p className={styles.pPolicy} style={{ textAlign: "justify" }}>
        {" "}
        <h1>Privacy Policy</h1>
        <br />
        <br />
        <h3>AmirMAghaei | International Restaurant (Demo)</h3>
        <br />
        <h6>Last updated: May 05, 2022</h6>
        <br />
        <br />
        <b>Note:</b> This website was created with the purpose of being a Demo
        and components such as Contact forms, Paypal gateway and
        delivery forms are also demo. You can try them as demo tools,
        For example the paypal gateway doesn't charge you as it's just a demo
        sandbox.
        <br />
        <br />
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit{" "}
        <Link to={"/Home"}>
          https://restaurant-demo-amirmaghaei.herokuapp.com.
        </Link>
        <br />
        <br />
        <h5 style={{ textAlign: "left" }}>PERSONAL INFORMATION WE COLLECT</h5>
        When you visit the Site, we automatically collect certain information
        about your device, including information about your web browser, IP
        address, time zone, and some of the cookies that are installed on your
        device. Additionally, as you browse the Site, we collect information
        about the individual web pages or products that you view, what websites
        or search terms referred you to the Site, and information about how you
        interact with the Site. We refer to this automatically-collected
        information as “Device Information.”
        <br />
        <br />
        <h5>We collect Device Information using these technologies:</h5>
        - “Cookies” are data files that are placed on your device or computer
        and often include an anonymous unique identifier.
        <br />
        - “Log files” track actions occurring on the Site, and collect data
        including your IP address, browser type, Internet service provider,
        referring/exit pages, and date/time stamps.
        <br />
        - “Web beacons,” “tags,” and “pixels” are electronic files used to
        record information about how you browse the Site.
        <br />
        <br />
        <h5>HOW DO WE USE YOUR PERSONAL INFORMATION?</h5>
        We use the Device Information that we collect to help us screen for
        potential risk and fraud (in particular, your IP address), and more
        generally to improve and optimize our Site (for example, by generating
        analytics about how our customers browse and interact with the Site, and
        to assess the success of our marketing and advertising campaigns).
        <br />
        <br />
        <h5>SHARING YOUR PERSONAL INFORMATION</h5>
        We share your Personal Information with third parties to help us use
        your Personal Information, as described above. For example, We use
        Google Analytics to help us understand how our customers use the
        Site--you can read more about how Google uses your Personal Information
        here:{" "}
        <a href="https://www.google.com/intl/en/policies/privacy/">
          https://www.google.com/intl/en/policies/privacy/
        </a>
        . You can also opt-out of Google Analytics here:{" "}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
        . We also use Google reCAPTCHA v3 to protect our site from spam and
        abuse. It uses advanced risk analysis techniques to tell humans and bots
        apart--You can read more about how Google uses your Personal Information
        here:{" "}
        <a href="https://www.google.com/intl/en/policies/privacy/">
          https://www.google.com/intl/en/policies/privacy/
        </a>
        <br />
        <br />
        Finally, we may also share your Personal Information to comply with
        applicable laws and regulations, to respond to a subpoena, search
        warrant or other lawful request for information we receive, or to
        otherwise protect our rights.
        <br />
        <br />
        <h5>DO NOT TRACK</h5>
        Please note that we do not alter our Site’s data collection and use
        practices when we see a Do Not Track signal from your browser.
        <br />
        <br />
        <h5>YOUR RIGHTS</h5>
        If you are a European resident, you have the right to access personal
        information we hold about you and to ask that your personal information
        be corrected, updated, or deleted. If you would like to exercise this
        right, please contact us through the contact information below.
        <br />
        Additionally, if you are a European resident we note that we are
        processing your information in order to fulfill contracts we might have
        with you (for example if you make an order through the Site), or
        otherwise to pursue our legitimate business interests listed above.
        Additionally, please note that your information will be transferred
        outside of Europe, including to Canada and the United States.
        <br />
        <br />
        <h5>DATA RETENTION</h5>
        When you place an order through the Site, we will maintain your Order
        Information for our records unless and until you ask us to delete this
        information.
        <br />
        <br />
        <br />
        <h5>CHANGES</h5>
        We may update this privacy policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal or
        regulatory reasons.
        <br />
        <br />
        <h5>CONTACT US</h5>
        For more information about our privacy practices, if you have questions,
        or if you would like to make a complaint, please contact us by e-mail at
        amir.agh@outlook.com
      </p>
    </div>
  );
}

export default PrivacyPolicy;
