import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./ContactUs.module.css";
import { Formik } from "formik";
import {
  validationSchema,
  initialValues,
  onSubmit,
} from "../store/formik/ContactUsFormik";
import ContactUsForm from "./ContactUs_Elements/ContactUsForm";
import ContactUsMap from "./ContactUs_Elements/ContactUsMap";

function ContactUs() {
  return (
    <Fragment>
      <Container className={styles["firstContainer"]}>
        <Col className={styles["contact-header"]}>
          <p className={styles["main-title"]}>Contact Us</p>
          <p className={styles["short-description"]}>
            You can simply book a table or Contact us using the following form
          </p>
        </Col>
      </Container>
      <Container className={styles["secondContainer"]}>
        <Row className="justify-content-center ">
          <Col className={`col-8 ${styles.col1}`}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => <ContactUsForm formik={formik} />}
            </Formik>
          </Col>
        </Row>

        <Row className="justify-content-center ">
          <Col className={`col-12 ${styles.col2}`}>
            <ContactUsMap />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ContactUs;
