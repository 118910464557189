import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./Reservation.module.css";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { ParallaxBanner } from "react-scroll-parallax";
import background from "../../assets/images/pexels-quark-studio-3201922.jpg";

function Reservation() {
  const navigate = useNavigate();

  const childPanel = (
    <Row>
      <Col className={styles["reservaton-col"]}>
        <p className={styles.pTitle}>EAT IN / TAKE AWAY</p>
        <p className={styles.pDescription}>
          Enjoy the time together by eating delicious cuisine in a cozy
          surrounding, or using the delivery option.
        </p>
        <div className={styles["reservaton-but"]}>
          <Button onClick={() => navigate("/ContactUs")} size="lg">
            Reservation
          </Button>
          <Button onClick={() => navigate("/Menu")} size="lg">
            Take Away (Menu)
          </Button>
        </div>
      </Col>
    </Row>
  );
  const makeSpeed = () => {
    if (isMobile) {
      return -10;
    } else {
      return -40;
    }
  };
  return (
    <>
      {!isMobile && (
        <Container
          className={`d-flex justify-content-center align-items-center ${styles["reservation-div"]}`}
        >
          {" "}
          {childPanel}
        </Container>
      )}
      {isMobile && (
        <ParallaxBanner
          className={styles["reservation-div1"]}
          layers={[
            {
              image: background,
              speed: makeSpeed(),
            },
          ]}
        >
          {childPanel}
        </ParallaxBanner>
      )}
    </>
  );
}

export default Reservation;
