import React from "react";
import { Modal } from "react-bootstrap";

const NotificationModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>Order Confirmation!</Modal.Header>
      <Modal.Dialog>
        <Modal.Body>
          <p>The order has been successfully Sent and Confirmed!</p>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default NotificationModal;
