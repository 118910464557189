import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./Footer.module.css";
import SocialMediaGroup from "../UI/SocialMediaGroup";
import {Link} from "react-router-dom";
import logo from './../../assets/images/MyBrand.svg';

function Footer() {
  const locations = [
    { city: "Lübeck", location: "110 Demo Street", tel: "01010101" },
    { city: "Berlin", location: "110 Demo Street", tel: "01010101" },
    { city: "London", location: "110 Demo Street", tel: "01010101" },
  ];
  return (
    <div className={`${styles["footer-div"]}`}>
      <Row>
        <Row>
          {locations.map((item, index) => {
            return (
              <Col
                key={index}
                style={{ marginTop: "5rem" }}
                className={`align-items-center `}
              >
                {" "}
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {item.city}
                </p>
                <p style={{ textAlign: "center" }}>{item.location}</p>
                <p style={{ textAlign: "center" }}>Tel: {item.tel}</p>
                <p style={{ textAlign: "center" }}>Open Hours: 9AM - 23PM</p>
              </Col>
            );
          })}
        </Row>

        <div className={styles.divSocialMeidiaGroup}>
          <SocialMediaGroup />
        </div>

        {/* <Row>
        <p style={{ marginTop: "3rem", fontSize: "15px", textAlign: "center" }}>
          Website Created and Designed by <a href="#">Amir.agh</a>
        </p>
      </Row> */}

        <div className={styles.divDesigner}>
          <p style={{ paddingTop: "2%", textAlign: "center" }}>
            Designed and Created by{" "}
            <img
                    src={logo}
                    height="50"
                    className="d-inline-block align-center"
                    alt="AmirBrand"
                  />
            {/* <a style={{ color: "white" }} href="https://amirmaghaei.com">
              AmirMAghaei
            </a> */}
          </p>
        </div>
      </Row>
      <div className={styles.divCopyright}>
        <Row>
          <hr className={styles.hrFooter} />
          <p style={{ textAlign: "center" }}>
            Copyright © 2022 Amirmohammad Aghaei | All rights reserved /{" "}
            <Link style={{ color: "white" }} to={"/privacy-policy"}>
              Privacy Policy.
            </Link>
          </p>
        </Row>
      </div>
    </div>
  );
}
export default Footer;
