import React, { Fragment } from "react";

import { SocialIcon } from "react-social-icons";

const SocialMediaGroup = () => {
  return (
    <Fragment>
      <SocialIcon
        url="https://www.facebook.com/amirmohammad.aghaei.9"
        network="facebook"
        style={{ height: 50, width: 150 }}
        bgColor="transparent"
        fgColor="white"
      />
      <SocialIcon
        url="https://www.linkedin.com/in/amirmohammad-aghaei-aa1532116/"
        network="linkedin"
        style={{ height: 50, width: 150 }}
        bgColor="transparent"
        fgColor="white"
      />
      <SocialIcon
        url="https://www.instagram.com/amiraria1/"
        network="instagram"
        style={{ height: 50, width: 150 }}
        bgColor="transparent"
        fgColor="white"
      />
    </Fragment>
  );
};

export default SocialMediaGroup;
