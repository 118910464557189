import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import styles from "./Menu.module.css";
import { Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { ParallaxBanner } from "react-scroll-parallax";
import backgroundImage from "../assets/images/pexels-roman-odintsov-4552131.jpg";

function Menu() {

  const makeSpeed = () => {
    if (isMobile) {
      return -10;
    } else {
      return -40;
    }
  };
  return (
    <Fragment>
      {!isMobile && <Container className={styles.parallax}> <Outlet /></Container>}
      {isMobile && (
        <ParallaxBanner
          className={styles["styles.parallax1"]}
          layers={[
            {
              image: backgroundImage,
              speed: makeSpeed(),
            },
          ]}
        >
           <Outlet />
        </ParallaxBanner>
      )}
    </Fragment>
  );
}
export default Menu;
