import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit"

export const fetchMeals = createAsyncThunk(
    //action type string
    "get/fetchMeals",
    // callback function
    async (thunkAPI) => {
      const response = await axios(`/api/meals/`);
      const loadsMeals = [...response.data];
      loadsMeals.map((meal) => {
        meal["id"] = meal["m_id"];
        delete meal["m_id"];
        return meal;
      });
      return loadsMeals;
    }
  );

  export const fetchMealByType = createAsyncThunk(
    //action type string
    "get/fetchMealByType",
    // callback function
    async (m_type, thunkAPI) => {
      const response = await axios(`/api/meals/${m_type}`);
      const loadsMeals = [...response.data];
      loadsMeals.map((meal) => {
        meal["id"] = meal["m_id"];
        delete meal["m_id"];
        return meal;
      });
      return loadsMeals;
    }
  );  