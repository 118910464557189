import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./components/main/NavBar";
import Footer from "./components/main/Footer";
import styles from "./pages/Menu.module.css";
import ContactUs from "./pages/ContactUs";
import Menu from "./pages/Menu";
import Shop from "./pages/Shop";
import MenuList from "./pages/MenuList";
import MealDetails from "./pages/MealDetails";
import Orders from "./pages/Orders";
import CheckOut from "./pages/CheckOut";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Home from "./pages/Home";
import ScrollToTop from "../src/components/UI/ScrollToTop";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";
import icon from "../src/assets/images/reshot-icon-chef-hat-J7DFG6M589.svg";
import ConsentBanner from "./components/PrivacyPolicy/ConsentBanner";
import ReactGA from "react-ga";

function App() {
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const reCaptchaAction = "homepage";

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", icon);
    ReactGA.initialize("UA-227900873-1");
    ReactGA.set({ anonymizeIp: true });
    ReactGA.pageview(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(reCaptchaAction);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  return (
    <div style={{ overflowX: "clip" }}>
      <NavBar />
      <div className={`App`}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Navigate replace to="/Home" />} />
            <Route path="/Home" element={<Home />} />

            <Route path="/Menu/" element={<Menu />}>
              <Route
                path="/Menu/"
                element={<Navigate replace to="MenuList" />}
              />
              <Route path="MenuList/*" element={<MenuList />} />
              <Route path="mealDetails/*" element={<MealDetails />} />
            </Route>
            <Route path="/Shop/" element={<Shop />}>
              <Route path="/Shop/" element={<Navigate replace to="Basket" />} />
              <Route path="Basket/" element={<Orders />} />
              <Route path="Checkout/" element={<CheckOut />} />
            </Route>
            <Route path="/ContactUs" element={<ContactUs />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </ScrollToTop>
      </div>
      <Footer />
      <ConsentBanner />
    </div>
  );
}
export default App;
