import React from "react";
import FoodOrderCard from "../sub_main/FoodOrderCard";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import styles from "./PurchaseCard.module.css";
import Orederform from "../sub_main/OrederForm";
import NotificationModal from "../UI/NotificationModal";
import { useSelector, useDispatch } from "react-redux";
import { foodMenuActions } from "../../store/foodMenuSlice";
import { useNavigate } from "react-router-dom";
import TotalAmount from "../sub_main/TotalAmount";
import { Formik } from "formik";
import {
  validationSchema,
  initialValues,
} from "../../store/formik/OrderFormFormik";

function PurchaseCard(props) {
  const orderConfirmFlag = useSelector(
    (state) => state.foodMenu.orderConfirmFlag
  );
  const foodOrderedData = useSelector(
    (state) => state.foodMenu.foodOrderedData
  );
  const navigate = useNavigate();
  const orderFlag = useSelector((state) => state.foodMenu.orderFlag);
  const totalAmount = useSelector((state) => state.foodMenu.totalAmount);
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting }) => {
    const deliveryInfo = {
      name: values.name,
      street: values.street,
      city: values.city,
      postalCode: values.postalCode,
      phoneNumber: values.phone,
      email: values.email,
    };
    dispatch(foodMenuActions.onSubmitForm(deliveryInfo));

    setTimeout(() => {
      // alert(JSON.stringify(values, null, 2));
      setSubmitting(false);
    }, 400);

    navigate("/Shop/Checkout");
  };

  return (
    <div>
      <NotificationModal
        show={orderConfirmFlag}
        onHide={() => {
          dispatch(foodMenuActions.closeNotification());
        }}
      />

      <Modal show={props.onShow} onHide={props.onClose} size="lg" centered >
        <Container className={styles["modal-content"]}>
          
            <Modal.Dialog>
              <Modal.Body className={styles["modal-content"]}>
                {foodOrderedData.map((foodItem) => (
                  <FoodOrderCard
                    key={foodItem.id}
                    id={foodItem.id}
                    name={foodItem.name}
                    price={foodItem.price}
                    value={foodItem.inputValue}
                    onAddItem={(id) => {
                      dispatch(foodMenuActions.add(id));
                    }}
                    onRemoveItem={(id) => {
                      dispatch(foodMenuActions.decrease(id));
                    }}
                  />
                ))}
                <Container>
                  <Row>
                    <TotalAmount amount={totalAmount} />
                    {!orderFlag && (
                      <Col className="d-flex col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 justify-content-end">
                        <Button
                          onClick={props.onClose}
                          className={styles["custome-button"]}
                          variant="dark"
                          size="sm"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            dispatch(foodMenuActions.onOrdered());
                            navigate("/Shop/Basket");
                          }}
                          className={styles["custome-button"]}
                          variant="dark"
                          size="sm"
                        >
                          Order
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <hr />

                  <Row>
                    {orderFlag && (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {(formik) => (
                          <Orederform formik={formik} onClose={props.onClose} />
                        )}
                      </Formik>
                    )}
                  </Row>
                </Container>
              </Modal.Body>
            </Modal.Dialog>
        
        </Container>
      </Modal>
    </div>
  );
}

export default PurchaseCard;
