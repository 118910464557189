import React, { Fragment } from "react";
import Payment from '../components/main/Payment'

function CheckOut() {
  return (
    <Fragment>
      <Payment />
    </Fragment>
  );
}

export default CheckOut;
