import * as Yup from "yup";


export const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  comment: "",
  select: "",
};

export const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(15, "Must be 15 characters or less!")
    .required("Required!"),
  lastName: Yup.string()
    .max(20, "Must be 20 characters or less!")
    .required("Required!"),
  phone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .required("Required!"),

  email: Yup.string().email("Invalid email address (@ and . are missing)").required("Required!"),
  comment: Yup.string().required("Required!"),
  select: Yup.string().required("Required!"),
});
export const onSubmit = (values, { setSubmitting }) => {
  setTimeout(() => {
    // alert(JSON.stringify(values, null, 2));
    setSubmitting(false);
  }, 400);}
