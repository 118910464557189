import { Fragment } from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import styles from './CardWrapper.module.css'

function CardWrapper(props) {
  return (
    <Fragment>
      <Container >
        <Row className="justify-content-center">
          <Col  className={props.columns}>
            <Card className={styles.card}>
              <Card.Body className={props.className}>
                {props.children}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CardWrapper;
