import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Badge, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { foodMenuActions } from "../../store/foodMenuSlice";

function CartButton(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Row>
      <Button
        onClick={() => {
          dispatch(foodMenuActions.openOrderCard());
          navigate("/Shop/Basket");
        }}
        variant="light"
        className={props.className}
      >
        <i className="fa-solid fa-cart-shopping"></i> Your Cart{" "}
        <Badge bg="dark"> {props.totalCount} </Badge>
      </Button>
    </Row>
  );
}

export default CartButton;
