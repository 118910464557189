import { createSlice } from "@reduxjs/toolkit";
import { fetchMeals, fetchMealByType } from "./foodMenuThunks";

const initialState = {
  foodOrderedData: [],
  deliveryInfo: {},
  orderFlag: false,
  orderConfirmFlag: false,
  closeCardFlag: false,
  purchaseListActivate: false,
  totalCount: 0,
  totalAmount: 0,
  selectedType: "All",
  selectedId : null,
  meals: [],
  selectedMeal: [],
  selectedMealById: [],
  mealTypes: [],
  loading: false,
  isErrorExist: false,
  formNotification: false
};

const foodMenuSlice = createSlice({
  name: "foodMenu",
  initialState,
  reducers: {
    addToCart(state, action) {
      const index = state.foodOrderedData.findIndex(
        (foodItem) => foodItem["id"] === action.payload["id"]
      );

      if (index !== -1) {
        state.foodOrderedData[index] = { ...action.payload };
      } else if (index === -1) {
        state.foodOrderedData.push(action.payload);
      }
    },
    countTheCount(state) {
      state.totalCount = state.foodOrderedData.reduce(
        (total, foodItem) => foodItem.inputValue + total,
        0
      );
    },

    countTotalAmount(state) {
      state.totalAmount = state.foodOrderedData.reduce(
        (total, foodItem) => foodItem.inputValue * foodItem.price + total,
        0
      );
    },
    add(state, action) {
      const index = state.foodOrderedData.findIndex(
        (foodItem) => foodItem["id"] === action.payload
      );

      if (state.foodOrderedData[index]["inputValue"] >= 11) {
        return;
      }
      state.foodOrderedData[index].inputValue++;
    },

    decrease(state, action) {
      const index = state.foodOrderedData.findIndex(
        (foodItem) => foodItem["id"] === action.payload
      );
      if (state.foodOrderedData[index]["inputValue"] <= 1) {
        state.foodOrderedData.splice([index], 1);
      } else {
        state.foodOrderedData[index].inputValue--;
      }
    },
    onSelectId(state, action){
      state.selectedId = action.payload
      state.selectedMealById = state.meals.filter((meal) => meal["id"] === state.selectedId);
    },
    onSelectType(state, action) {
      state.selectedType = action.payload;
      if (state.selectedType === "All") {
        state.selectedMeal = state.meals;
      } else {
        state.selectedMeal = state.meals.filter(
          (item) => item["m_type"] === state.selectedType
        );
      }
    },
    onOrdered(state) {
      state.orderFlag = true;
    },
    onConfirm(state) {
      state.orderConfirmFlag = true;
      state.closeCardFlag = true;
    },
    // onCheckOut(state) {
    //   state.orderFlag = false;
    // },
    onSubmitForm(state, action) {
      state.deliveryInfo = action.payload;
    },
    closeNotification(state) {
      state.orderConfirmFlag = false;
      state.purchaseListActivate = false;
    },
    openOrderCard(state) {
      state.purchaseListActivate = true;
    },
    closeOrderCard(state) {
      state.purchaseListActivate = false;
    },
    loadFormNotification(state, action){
      state.formNotification = action.payload;
    }
  },
  extraReducers: {
    [fetchMeals.pending]: (state) => {
      state.loading = true;
    },
    [fetchMeals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.meals = payload.map(item=> ({...item, "images": item["images"].map(image=>({src:image}))}));

      // The situation when one type of dish is picked in MealsNav (for when the page is refreshed)
      if (state.selectedType === "All") {
        state.selectedMeal = state.meals;
      } else {
        state.selectedMeal = state.meals.filter(
          (item) => item["m_type"] === state.selectedType
        );
      }
      // The situation when one dish is picked to visit the photo gallery and description (for when the page is refreshed)
      if (state.selectedId!==null){
        state.selectedMealById = state.meals.filter((meal) => meal["id"].toString() === state.selectedId);
      }

      let mealTypes = payload.map((meal) => meal["m_type"]);
      mealTypes.push("All");
      mealTypes.sort();
      state.mealTypes = [...new Set(mealTypes)];
    },
    [fetchMeals.rejected]: (state) => {
      state.loading = false;
      state.isErrorExist = true;
    },
    ///////////////////////////
    [fetchMealByType.pending]: (state) => {
      state.loading = true;
    },
    [fetchMealByType.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.meals = payload;
      state.selectedMeal = payload.map;
      let mealTypes = payload.map((meal) => meal["m_type"]);
      mealTypes.push("All");
      mealTypes.sort();
      state.mealTypes = [...new Set(mealTypes)];
    },
    [fetchMeals.rejected]: (state) => {
      state.loading = false;
      state.isErrorExist = true;
    },
  },
});
export default foodMenuSlice;
export const foodMenuActions = foodMenuSlice.actions;