import React, { Fragment, useState } from "react";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import styles from "../main/PurchaseCard.module.css";
import { useSelector, useDispatch } from "react-redux";
import { foodMenuActions } from "../../store/foodMenuSlice";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "formik";

const Orederform = (props) => {
  return (
    <Fragment>
      <Row style={{ marginTop: "2rem" }}>
        <p className={styles.pFormFont}>Delivery Form</p>
      </Row>
      <Form
        noValidate
        // validated={validated}
        onSubmit={props.formik.handleSubmit}
      >
        <Col>
          <Row>
            <Col>
              <Row className="d-flex justify-content-center">
                <ErrorMessage name="name" />
              </Row>
              <Form.Group className="mb-5">
                <Form.Label>Name</Form.Label>
                <FormControl
                  required
                  aria-label="Name"
                  placeholder="Full Name*"
                  id="name"
                  name="name"
                  isInvalid={
                    props.formik.touched.name && props.formik.errors.name
                  }
                  {...props.formik.getFieldProps("name")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row className="d-flex justify-content-center">
                <ErrorMessage name="street" />
              </Row>
              <Row className="d-flex justify-content-center">
                <ErrorMessage name="city" />{" "}
              </Row>
              <Row className="d-flex justify-content-center">
                <ErrorMessage name="postalCode" />
              </Row>
              <Form.Group className="mb-5">
                <Form.Label>Address</Form.Label>
                <FormControl
                  className="mb-3"
                  required
                  aria-label="Street"
                  placeholder="Street*"
                  id="street"
                  name="street"
                  isInvalid={
                    props.formik.touched.street && props.formik.errors.street
                  }
                  {...props.formik.getFieldProps("street")}
                />
                {/* <ErrorMessage name="street" /> */}

                <FormControl
                  className="mb-3"
                  required
                  type="text"
                  aria-label="City"
                  placeholder="City*"
                  id="city"
                  name="city"
                  isInvalid={
                    props.formik.touched.city && props.formik.errors.city
                  }
                  {...props.formik.getFieldProps("city")}
                />
                {/* <ErrorMessage name="city" /> */}
                <FormControl
                  required
                  type="text"
                  aria-label="Postal Code"
                  placeholder="Postal Code*"
                  id="postalCode"
                  name="postalCode"
                  isInvalid={
                    props.formik.touched.postalCode &&
                    props.formik.errors.postalCode
                  }
                  {...props.formik.getFieldProps("postalCode")}
                />
                {/* <ErrorMessage name="postalCode" /> */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row className="d-flex justify-content-center">
                <ErrorMessage name="phone" />
              </Row>
              <Row className="d-flex justify-content-center">
                <ErrorMessage name="email" />
              </Row>
              <Form.Group className="mb-5">
                <Form.Label>Contact</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>+</InputGroup.Text>
                  <FormControl
                    required
                    type="text"
                    aria-label="Phone"
                    placeholder="Phone number*"
                    id="phone"
                    name="phone"
                    isInvalid={
                      props.formik.touched.phone && props.formik.errors.phone
                    }
                    {...props.formik.getFieldProps("phone")}
                  />
                </InputGroup>
                <FormControl
                  className="mb-3"
                  aria-label="Email"
                  placeholder="Email*"
                  type="email"
                  id="email"
                  name="email"
                  isInvalid={
                    props.formik.touched.email && props.formik.errors.email
                  }
                  {...props.formik.getFieldProps("email")}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex justify-content-end">
          <Button
            onClick={props.onClose}
            className={styles["form-buttons"]}
            variant="dark"
            size="sm"
            type="button"
          >
            Cancel
          </Button>
          <Button
            // onClick={() => {
            //   navigate("/Checkout")
            // }}
            className={styles["form-buttons"]}
            variant="dark"
            size="sm"
            type="submit"
          >
            Checkout
          </Button>
        </Col>
      </Form>
    </Fragment>
  );
};

export default Orederform;
