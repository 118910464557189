import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./AdressInfo.module.css";

function AddressInfo() {
  const { deliveryInfo } = useSelector((state) => state.foodMenu);
  return (
    <div className={styles.divAdress}>
      <p className={styles.deliveryTag}>Delivery Information</p>
      <div className={styles.addressInfoKey}>
        <p>Name:  {deliveryInfo.name}</p>
        <p>
          Address:  {deliveryInfo.street}, {deliveryInfo.city},{" "}
          {deliveryInfo.postalCode}
        </p>
        <p>Phone Number:  +{deliveryInfo.phoneNumber} </p>
        <p>Email:  {deliveryInfo.email}</p>
      </div>

    </div>
  );
}

export default AddressInfo;
