import React from "react";
import { Nav, NavItem, Navbar  } from "react-bootstrap";
import styles from "./MealsNav.module.css";
import { useSelector, useDispatch } from "react-redux";
import { foodMenuActions } from "../../store/foodMenuSlice";
import { LinkContainer } from "react-router-bootstrap";

function MealsNav() {
  const mealTypes = useSelector((state) => state.foodMenu.mealTypes);
  // const meals = useSelector((state) => state.foodMenu.meals);
  const dispatch = useDispatch();
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="xxxl"
        variant="dark"
        className={styles.navbarClass}
      >
        <Navbar.Toggle  style={{backgroundColor:'black'}} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={styles.navCollapse}
         
        >
          <Nav
            variant="tabs"
            defaultActiveKey="/Menu/All"
            style={{ borderBottom: "1px black" }}
            expand="md"
          >
            {mealTypes.map((type) => (
              <NavItem
                className={styles["custom-navItem"]}
                onClick={() => {
                  dispatch(foodMenuActions.onSelectType(type));
                }}
                key={type}
              >
                <LinkContainer to={`/Menu/?type=${type}`}>
                 
                  <Nav.Link
                    className={styles["custom-navLink"]}
                  >
                     {`${type}`}
                     </Nav.Link>
                
                </LinkContainer>
              </NavItem>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default MealsNav;
