import React, { Fragment, useCallback } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function Paypal() {
  const totalAmount = useSelector((state) => state.foodMenu.totalAmount);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const reCaptchaAction = "Ecommerce";

  const initialOptionsPaypal = {
    "client-id":
      "AU3KacTBvwnjzSVnR_yf1MuUFg3wHS5lK6Ko571CsGX-acV9e4GAH9Q6lblfCNTta4_XwsuNBd69h4pj",
    currency: "EUR",
    intent: "capture",
  };

  const reCaptchaHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const result = await executeRecaptcha(reCaptchaAction);
  }, [executeRecaptcha]);

  return (
    <Fragment>
      <PayPalScriptProvider options={initialOptionsPaypal}>
        <PayPalButtons
          onClick={reCaptchaHandler}
          style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "EUR",
                    value: `${totalAmount}`,
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              const name = details.payer.name.given_name;
              alert(`Transaction completed by ${name}`);
            });
          }}
        />
      </PayPalScriptProvider>
    </Fragment>
  );
}

export default Paypal;
