import React, { Fragment, useRef, useState } from "react";
import CardWrapper from "../components/UI/CardWrapper";
import styles from "./MealDetails.module.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { foodMenuActions } from "../store/foodMenuSlice";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import ErrorHandler from "../components/main/ErrorHandler";

function MealDetails() {
  const { selectedMealById } = useSelector((state) => state.foodMenu);
  const refQuantity = useRef(1);
  const location = useLocation();
  const pk = new URLSearchParams(location.search).get("id");
  const dispatch = useDispatch();
  const [sumPrice, setSumPrice] = useState(0);

  const onAddToCard = () => {
    const foodData = {
      id: pk,
      name: selectedMealById[0].name,
      price: selectedMealById[0].price,
      inputValue: +refQuantity.current.value,
    };
    dispatch(foodMenuActions.addToCart(foodData));
  };
  return (
    <Fragment>
      <Container>
        <Row>
          <Col className={styles.mealDetailsPage}>
            <CardWrapper columns="col-12" className={styles.mealDetailsCard}>
              {selectedMealById[0] && (
                <Container>
                  <Row>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                      <Carousel
                        hasMediaButton={false}
                        images={selectedMealById[0]["images"]}
                      />
                    </Col>

                    <Col className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                      <Row>
                        <p className={styles.titleMeal}>
                          {selectedMealById[0].name}
                        </p>
                        <p className={styles.description}>
                          {selectedMealById[0]["long_description"]}
                          <sup>
                            <a href={selectedMealById[0]["address_url"]}>[1]</a>
                          </sup>
                        </p>

                        <p className={styles.titleIngrediant}>Ingredients</p>
                        <p className={styles.description}>
                          {selectedMealById[0]["ingredients"].map(
                            (ingredient, index) => (
                              <li key={index}>{ingredient}</li>
                            )
                          )}
                        </p>
                      </Row>

                      <Row className={styles.orderRow}>
                        <Col className="col-9">
                          <Row className={styles.priceTag}>PRICE</Row>
                          <Row>
                            <Col
                              className={`d-flex justify-content-start ${styles.mealPrice}`}
                              lg="1"
                            >
                              {`€`}
                              {sumPrice.toFixed(2)}
                            </Col>
                          </Row>
                        </Col>

                        <Col className="d-flex justify-content-end col-3">
                          <Form>
                            <Form.Group className="mb-3">
                              <Form.Label className={styles.quantityTag}>
                                QUANTITY
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  // className={styles.form}
                                  onChange={() => {
                                    setSumPrice(
                                      selectedMealById[0].price *
                                        +refQuantity.current.value
                                    );
                                  }}
                                  size="sm"
                                  as="input"
                                  type="number"
                                  defaultValue="0"
                                  step="1"
                                  ref={refQuantity}
                                  min="0"
                                  max="11"
                                />
                              </Col>
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-end justify-content-end ">
                        <Button
                          className={styles.addToCartButton}
                          onClick={onAddToCard}
                        >
                          ADD TO BASKET
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              )}
              <ErrorHandler />
            </CardWrapper>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default MealDetails;
