import React from "react";
import PurchaseCard from "../components/main/PurchaseCard";
import { useSelector, useDispatch } from "react-redux";
import { foodMenuActions } from "../store/foodMenuSlice";
import { useNavigate } from "react-router-dom";

function Orders() {
  const purchaseListActivate = useSelector(
    (state) => state.foodMenu.purchaseListActivate
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <PurchaseCard
        onShow={purchaseListActivate}
        onClose={() => {
          dispatch(foodMenuActions.closeOrderCard());
          navigate("/Menu")
        }}
      />
    </div>
  );
}

export default Orders;
