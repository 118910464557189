import React from "react";
import { Container, Ratio, Row, Col } from "react-bootstrap";
import CardWrapper from "../../components/UI/CardWrapper";

const ContactUsMap = () => {
  return (
    <Container style={{ marginTop: "3rem", marginBottom: "4rem" }}>
      <p style={{ fontSize: "xx-large" }}>Location</p>
      <Row style={{ marginTop: "2rem" }}>
        <Col
          className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 justify-content-center"
          style={{ paddingTop: "3%" }}
        >
          <CardWrapper
            title="Location"
            
          >
            <p>Demo Address, 23562 Lübeck</p>
            <p>Open Hours: 11:00 AM to 23:00 PM</p>
            <p>Email: Amir.agh@outlook.com</p>
            <p>Tel: 0101010101 (Demo)</p>
          </CardWrapper>
        </Col>
        <Col
          className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 justify-content-center"
          style={{ paddingTop: "3%" }}
        >
          <Ratio aspectRatio="16x9">
          
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1978.3641664417182!2d10.677581503540917!3d53.86625365381897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b20956b18f5377%3A0x338bbd2e4216876!2sHolstentor%20square!5e0!3m2!1sen!2s!4v1650568027309!5m2!1sen!2s"
              title="myFrame"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Ratio>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUsMap;
