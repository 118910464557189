import React, { Fragment } from "react";
import {Col} from "react-bootstrap"

function TotalAmount(props) {
  return (
    <Fragment>
      <Col className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <p className="d-flex justify-content-start" style={{ marginRight: "1rem" }}>
          <b>Total Ammount : €{props.amount.toFixed(2)}</b>
        </p>
      </Col>
    </Fragment>
  );
}

export default TotalAmount;
