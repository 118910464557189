import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./OurStory.module.css";
import { Link } from "react-router-dom";

function OurStory() {
  return (
    <Container
      className={`d-flex justify-content-center align-items-center ${styles["ourStory-div"]}`}
    >
      <Row className="justify-content-center">
        <Col className="col-6 justify-content-center ">
          <p className={styles.pTitle}>Our Story</p>
          <p className={styles.pDescription}>
            Despite many varieties, food is something that brings us together as
            a human race. Also it's a way to make us familiar with other tastes,
            cultures, colors and etc. You can order and try International
            cuisines via <em>"RestaurantName"</em>. To do that, click on the{" "}
            <Link style={{textDecoration:"none",color:"black",  fontWeight:'bold' }} to="/Menu">Menu</Link> and enjoy a variety of dishes from
            different countries. You can also check the description and
            ingredients of dishes by clicking on the photo of each cuisine.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default OurStory;
