import React, { Fragment } from "react";
import DeliveryInfo from "../sub_main/DeliveryInfo";
import Paypal from "../sub_main/Paypal";
import CardWrapper from "../UI/CardWrapper";
import styles from "./Payment.module.css";
import { Row, Col } from "react-bootstrap";
import AddressInfo from "../sub_main/AddressInfo";

function Payment() {
  return (
    <Fragment>
      <CardWrapper columns="col-12" className={styles.paymentCard}>
        <div style={{ border: "1px solid #7e7a7a80", margin: "1rem" }}>
          <Row className={styles.rowPayment}>
            <DeliveryInfo />
            <Row
              className="align-items-center justify-content-center"
              style={{ marginTop: "1rem" }}
            >
              <Col className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                <AddressInfo />
              </Col>
              <Col className="justify-content-center col-12 col-sm-12 col-md-4 col-lg-6 col-xl-6 pt-4" >
                <Paypal />
              </Col>
            </Row>
          </Row>
        </div>
      </CardWrapper>
    </Fragment>
  );
}

export default Payment;
