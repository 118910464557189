import { ErrorMessage } from "formik";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import styles from "./ContactUsForm.module.css";
import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {foodMenuActions} from "../../store/foodMenuSlice";
import ContactFormNotifications from "../../components/UI/notifications/ContactFormNotifications";

function ContactUsForm(props) {
  const { formNotification } = useSelector((state) => state.foodMenu);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Form noValidate onSubmit={props.formik.handleSubmit}>
        <Row>
          <Col lg="6">
            <Form.Group className="mb-4">
              <Form.Control
                type="text"
                placeholder="First Name"
                id="firstName"
                name="firstName"
                isInvalid={
                  props.formik.touched.firstName &&
                  props.formik.errors.firstName
                }
                {...props.formik.getFieldProps("firstName")}
              />
              <ErrorMessage name="firstName" />
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-4">
              <Form.Control
                type="text"
                placeholder="Last Name"
                id="lastName"
                name="lastName"
                isInvalid={
                  props.formik.touched.lastName && props.formik.errors.lastName
                }
                {...props.formik.getFieldProps("lastName")}
              />
              <ErrorMessage name="lastName" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Form.Group className="mb-4">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">+</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  id="phone"
                  name="phone"
                  isInvalid={
                    props.formik.touched.phone && props.formik.errors.phone
                  }
                  {...props.formik.getFieldProps("phone")}
                />
              </InputGroup>
              <ErrorMessage name="phone" />
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-4">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                id="email"
                name="email"
                isInvalid={
                  props.formik.touched.email && props.formik.errors.email
                }
                {...props.formik.getFieldProps("email")}
              />
              <ErrorMessage name="email" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Select
              aria-label="Default select example"
              className="mb-3"
              id="select"
              name="select"
              isInvalid={
                props.formik.touched.select && props.formik.errors.select
              }
              {...props.formik.getFieldProps("select")}
            >
              <option>Please choose a topic </option>
              <option value="1">Feedback</option>
              <option value="2">Appointment</option>
              <option value="3">
                Others (Please provide in comment field)
              </option>
            </Form.Select>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Comment"
            id="comment"
            name="comment"
            isInvalid={
              props.formik.touched.comment && props.formik.errors.comment
            }
            {...props.formik.getFieldProps("comment")}
          />
          <ErrorMessage name="comment" />
        </Form.Group>

        <div className={`d-grid mb-3 ${styles["form-but"]}`}>
          <Button
            onClick={() => {
              dispatch(foodMenuActions.loadFormNotification(true));
  
            }}
            type="submit"
          >
            send
          </Button>
           <ContactFormNotifications
            show={formNotification}
            onHide={() => dispatch(foodMenuActions.loadFormNotification(false))}
            formRejection={false}
          />
        </div>
      </Form>
    </Fragment>
  );
}

export default ContactUsForm;
