import React, { useRef } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import styles from "./FoodItem.module.css";
//import OrderContext from '../../context/order-context';

function FoodOrderCard(props) {
  const userInputId = useRef(null);

  return (
    <Container>
      <Row>
        <Col sm="3" className="col-6 col-md-6 col-lg-6 col-xl-6 col-sm-6">
          <Row className={styles.foodname}>{props.name}</Row>
          <Row className={styles.foodPrice}>
            {`€${(props.price * props.value).toFixed(2)}`}
          </Row>
        </Col>

        <Col className="col-6 col-md-6 col-lg-6 col-xl-6 col-sm-6  ">
          <Row>
            <Col className="d-flex justify-content-end">
              <Form>
                <Form.Group  className="mb-2 pt-2" controlId={props.id}>
                  <Form.Label column size="sm">
                    Quantity
                  </Form.Label>

                  <Form.Control
                    ref={userInputId}
                    className={styles.form}
                    size="sm"
                    as="input"
                    type="number"
                    value={props.value}
                    step="1"
                    min="0"
                    max="11"
                    disabled
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                variant="dark"
                size="sm"
                className={styles["add-but"]}
                onClick={() => {
                  props.onAddItem(props.id);
                }}
              >
                +
              </Button>

              <Button
                variant="dark"
                size="sm"
                className={styles["minus-but"]}
                onClick={() => {
                  props.onRemoveItem(props.id);
                }}
              >
                -
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr/>
    </Container>
  );
}

export default FoodOrderCard;
