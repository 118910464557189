import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import FoodOrderCard from "../sub_main/FoodOrderCard";
import { foodMenuActions } from "../../store/foodMenuSlice";
import TotalAmount from "./TotalAmount";
import styles from './DeliveryInfo.module.css'

function DeliveryInfo() {
  const { foodOrderedData, totalAmount } = useSelector(
    (state) => state.foodMenu
  );
  const dispatch = useDispatch();
 

  return (
    <Fragment>
      <p className={styles.orderTag}>Orders</p>

      {foodOrderedData.map((foodItem) => (
        <FoodOrderCard
          key={foodItem.id}
          id={foodItem.id}
          name={foodItem.name}
          price={foodItem.price}
          value={foodItem.inputValue}
          onAddItem={(id) => {
            dispatch(foodMenuActions.add(id));
          }}
          onRemoveItem={(id) => {
            dispatch(foodMenuActions.decrease(id));
          }}
        />
      ))}
      <div>
        <TotalAmount amount={totalAmount} />
        <hr />
      </div>
    </Fragment>
  );
}

export default DeliveryInfo;
